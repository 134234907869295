import React from 'react';
import { Grid as SCGGrid, Cell as SCGCell } from 'styled-css-grid';
//--- Stylesheets
import './style.scss';

const Grid = (props) => <SCGGrid {...props} className="grid-width"/>;

Grid.Cell = SCGCell;

export default Grid;
