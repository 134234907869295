import React, { Component } from 'react';
//--- Custom Component
import { SectionContainer } from '../widgets';
//--- Stylesheets
import './style.scss';

class Header extends Component {
	render() {
		return (
		<footer>
			<div/>
      	Pranavan Selladurai &#9400; {(new Date().getFullYear())}
      </footer>
		)
	}
}

export default Header;
