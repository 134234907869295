import React, { Component } from 'react';
//--- Custom Component
import BaseLayout from '../../components/BaseLayout';
import { Grid, Headline, SectionContainer, SkillRating, SocialIcon, ProgressRectangular } from '../../components/widgets';
//--- Image
import coverBg from '../../assets/img/bg/piriArtworkBg_blur.JPG'
import { ReactComponent as PiriSillouette} from '../../assets/img/piri_sillouette.svg';
import { ReactComponent as PiriLogo } from '../../assets/img/logo_piri.svg';
//--- Stylesheets
import './style.scss';
//--- Data
import dataHome from '../../data/home.json';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCoverBgHover: false,
			experienceYears: new Date().getFullYear() - dataHome.about.year
		};
		this.handleHoverOn = this.handleHoverOn.bind(this);
		this.handleHoverOff = this.handleHoverOff.bind(this);
	}
	handleHoverOn(event) { this.setState({ isCoverBgHover: true }) };
	handleHoverOff(event) { this.setState({ isCoverBgHover: false }) };

  render() {
    return (
			<BaseLayout>
				<div className={`home-cover ${this.state.isCoverBgHover ? 'switchBgToDeco' : ''}`}>
					<SectionContainer className="header">
						<div className="cover-bg-content" onMouseEnter={this.handleHoverOn} onMouseLeave={this.handleHoverOff}  style={{maxHeight: '100%', maxWidth: 768}}>
							<svg>
								<defs>
										<pattern id="cover_img" height="100%" width="100%" patternContentUnits="objectBoundingBox">
												<image height="1" width="1" preserveAspectRatio="none" xlinkHref={coverBg} />
										</pattern>
								</defs>
							</svg>
							<PiriLogo className="pSelladurai-logo fill-secondaryColor" style={{ maxHeight: 768, maxWidth: 768 }} />
						</div>
					</SectionContainer>
				</div>		
				<div className="home-content">
					<SectionContainer className="about">
					<Grid columns={3}>
						<Grid.Cell width={1}>
							<div
								data-aos="fade-up"
								data-aos-duration="1000"
									data-aos-anchor-placement="center-center"
									className="margin-top-48px"
								>
									<div className="super-title">
										<PiriSillouette alt="Piri Portrait" className="fill-primaryColor" />
									</div>
							</div>
						</Grid.Cell>
						<Grid.Cell width={2}>
							<div
								data-aos="fade-down"
								data-aos-duration="1000"
								data-aos-anchor-placement="top-center"
								className="content"
							>
								<Headline as="h1">{dataHome.about.title}</Headline>
								<div className="txt">
									<p>{dataHome.about.text}</p>
									<div className="social-icon-wrapper">
										<SocialIcon abbr="F">Facebook</SocialIcon>
										<SocialIcon abbr="IN">Linked in</SocialIcon>
									</div>
								</div>
							</div>
						</Grid.Cell>
					</Grid>
				</SectionContainer>
				<SectionContainer className="experience">
					<Grid columns={3}>
						<Grid.Cell width={1} />
						<Grid.Cell width={2}>
							<Headline as="h1" className="margin-bottom-48px">Experience</Headline>
						</Grid.Cell>
					</Grid>
					{dataHome.experience.map((elem, index) => (
						<div
							data-aos="fade-down"
							data-aos-duration="1000"
							data-aos-anchor-placement="top-bottom"
							className="home-item"
							key={index}
						>
							<Grid columns={3}>
								<Grid.Cell width={1}>
									<div className="home-section-title">
										<Headline as="h4">
											{elem.period}
											<div/>
										</Headline>
									</div>
								</Grid.Cell>
								<Grid.Cell width={2}>
									<div className="home-content">
										<Headline as="h2">{elem.position}</Headline>
										<Headline as="h5" className="margin-top-16px">{elem.company}</Headline>
										<p>{elem.comment}</p>
									</div>
								</Grid.Cell>
							</Grid>
						</div>
					))}
				</SectionContainer>
				<SectionContainer className="education">
					<Grid columns={3}>
						<Grid.Cell width={1} />
						<Grid.Cell width={2}>
							<Headline as="h1" className="margin-bottom-48px">Education</Headline>
						</Grid.Cell>
					</Grid>
					{dataHome.education.map((elem, index) => (
						<div
							data-aos="fade-down"
							data-aos-duration="1000"
							data-aos-anchor-placement="top-bottom"
							className="home-item"
							key={index}
						>
							<Grid columns={3}>
								<Grid.Cell width={1}>
									<div className="home-section-title">
										<Headline as="h4">
											{elem.period}
											<div />
										</Headline>
									</div>
								</Grid.Cell>
								<Grid.Cell width={2}>
									<div className="home-content">
										<Headline as="h2">{elem.eduTitle}</Headline>
										<Headline as="h5" className="margin-top-16px">{elem.institute}</Headline>
										<p>{elem.comment}</p>
									</div>
								</Grid.Cell>
							</Grid>
						</div>
					))}
				</SectionContainer>
				<SectionContainer className="skills">
					<Grid columns={3}>
						<Grid.Cell width={1} />
						<Grid.Cell width={2}>
							<Headline as="h1" className="margin-bottom-48px">Skills</Headline>
						</Grid.Cell>
					</Grid>
					{dataHome.skills.map((elem, index) => (
						<div
							data-aos="fade-down"
							data-aos-duration="1000"
							data-aos-anchor-placement="top-bottom"
							className="home-item margin-bottom-40px"
							key={index}
						>
							<Grid columns={3}>
								<Grid.Cell width={1}>
									<div className="home-section-title">
										<Headline as="h4">
											{elem.category}
											<div />
										</Headline>
									</div>
								</Grid.Cell>
								<Grid.Cell width={2}>
									<Grid columns={2}>
										{elem.skillSet.map((subElem, subIndex) => (
											<Grid.Cell width={1} key={subIndex}>
												{elem.category === 'Language' ? (
													<ProgressRectangular name={subElem.area} size={182.433} progress={subElem.rating}/>
												) : (
													<SkillRating name={subElem.area} rating={subElem.rating} />
												)}
											</Grid.Cell>
										))}
									</Grid>
								</Grid.Cell>
							</Grid>
						</div>
					))}
					</SectionContainer>
				</div>
			</BaseLayout>
    );
  }
}

export default Home;