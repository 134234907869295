import React, { Component } from 'react';
//--- Stylesheets
import './style.scss';

class SectionContainer extends Component {
	render() {
		return (
			<section className={`section-container ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
				{this.props.children}
			</section>
		)
	}
}

export default SectionContainer;
