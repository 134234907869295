import React from 'react';
//import 'react-app-polyfill/ie9';
//import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom'
//--- Custom Components
import Home from './scenes/Home';
import Photography from './scenes/Photography';
import Projects from './scenes/Projects';
import ErrorPage from './scenes/ErrorPage';
//--- Scripts
import * as serviceWorker from './serviceWorker';
//--- Stylesheets
import './styles/style.scss';


ReactDOM.render((
	<HashRouter>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/photo' component={Photography} />
      <Route path='/projects' component={Projects} />
      <Route component={ErrorPage} />
    </Switch>
	</HashRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
