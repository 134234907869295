import React, { Component } from 'react';
//--- Stylesheets
import './style.scss';

class SkillRating extends Component {
	createSkill = () => {
		let maxRating = 10;
		let ratingPoints = [];
		for (let i = 0; i < this.props.rating; i++){
			ratingPoints.push(<div className="skill-rating-point active" key={i + '-act'}/>)
		}
		for (let i = 0; i < (maxRating - this.props.rating); i++){
			ratingPoints.push(<div className="skill-rating-point"  key={i + '-inact'}/>)
		}
		
		return ratingPoints;
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="skill">
					<div className="skill-name">{ this.props.name }</div>
					<div className="skill-rating">{ this.createSkill() }</div>
				</div>
			</React.Fragment>
    )
  }
}

export default SkillRating;
