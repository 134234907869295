import React, { Component } from 'react';
import AOS from 'aos';
//--- Custom Component
import Header from "../Header";
import Footer from "../Footer";
//--- Stylesheets
import './style.scss';
import 'aos/dist/aos.css';

AOS.init();

class BaseLayout extends Component {
  render(){
    return(
			<div className={`main-container ${this.props.invert ? 'invert' : ''}`}>
				<Header />
        {this.props.children}
        <Footer />
			</div>
    )
  }
}

export default BaseLayout;
