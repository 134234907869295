import React, { Component } from 'react';
//--- Stylesheets

class Icon extends Component {
	render() {
		return (
			<i className={`icon ${this.props.name}`} />
		)
	}
}

export default Icon;
