import React, { Component } from 'react';
import Icon from '../Icon/index';
import ReactMapGL, { Marker } from 'react-map-gl';

// --- Scripts
import Exif from 'exif-js';
//--- Stylesheets
import './style.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
// --- Variables
const images = require.context('../../../../public/photography/demo');
const MODAL_OPEN_CLASS = "modal-is-open";


class Modal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentImage: this.props.currentImage,
			camera: '',
			model: 'EOS 60D',
			isoNumber: 400,
			exposureTime: '1/3200 Sec',
			aperture: 'f/1.7',
			focalLength: '4.2 mm',
			latitude: 46.946459,
			latRef: '"N',
			longitude: 7.444050,
			lngRef: '"W',
			viewport: {
				width: '100%',
				height: '100%'
			}
		};
		this.onLoadReadExif = this.onLoadReadExif.bind(this);
		this.keyboardNav = this.keyboardNav.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
		this.imgRef = React.createRef();
	}

	keyboardNav(event) {
		if (this.props.photography){
    	if (event.keyCode === 27) { this.props.isClose(); }
    	if (event.keyCode === 37) { this.gotoPrevious(); }
			if (event.keyCode === 39) { this.gotoNext(); }
		}
	}
	gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage !== 0 ? this.state.currentImage - 1 : 0
		});
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage !== this.state.totalImage - 1 ? this.state.currentImage + 1 : this.state.totalImage - 1
		});
  }

	componentDidMount(){
		document.addEventListener("keydown", this.keyboardNav, false);
		document.body.classList.add(MODAL_OPEN_CLASS);
  }
  componentWillUnmount(){
		document.removeEventListener("keydown", this.keyboardNav, false);
		document.body.classList.remove(MODAL_OPEN_CLASS);
	}
	
	onLoadReadExif(){
		var imageElement = this.imgRef.current;

		Exif.getData(imageElement, () => {
			var TempCamera = Exif.getTag(imageElement, 'Make');
			var TempModel = Exif.getTag(imageElement, 'Model');
			var TempISO = Exif.getTag(imageElement, 'ISOSpeedRatings');
			var TempExposureTime = parseFloat(Exif.getTag(imageElement, 'ExposureTime'));
			var TempAperture = parseFloat(Exif.getTag(imageElement, 'FNumber'));
			var TempFocalLength = parseFloat(Exif.getTag(this, "FocalLength"));	
			var TempLat = Exif.getTag(this, "GPSLatitude");
			var TempLatRef = Exif.getTag(this, "GPSLatitudeRef");
			var TempLng = Exif.getTag(this, "GPSlongitude");
			var TempLngRef = Exif.getTag(this, "GPSlongitudeRef");
			
			var lat;
			var lng;

			if (TempLat && TempLng && TempLatRef && TempLngRef) {
				lat = TempLngRef === 'W' ? -1 * (TempLat[0] + (TempLat[1] / 60.0) + (TempLat[2] * 1/3600)) : TempLat[0] + (TempLat[1] / 60.0) + (TempLat[2] * 1/3600);
				lng = TempLatRef === 'S' ? -1 * (TempLng[0] + (TempLng[1] / 60.0) + (TempLng[2] * 1 / 3600)) : TempLng[0] + (TempLng[1] / 60.0) + (TempLng[2] * 1 / 3600);

				this.setState({
					latitude: lat,
					latRef: '"' + TempLat,
					longitude: lng,
					lngRef: '"' + TempLngRef
				});
			} else if (this.props.images[this.state.currentImage].lat && this.props.images[this.state.currentImage].lng) {
				this.setState({
					latitude: this.props.images[this.state.currentImage].lat,
					latRef: '"' + this.props.images[this.state.currentImage].latRef,
					longitude: this.props.images[this.state.currentImage].lng,
					lngRef: '"' + this.props.images[this.state.currentImage].lngRef,
				})
			} else {
				this.setState({
					latitude: null,
					latRef: '',
					longitude: null,
					lngRef: ''
				})
			}

			this.setState({
				camera: (TempCamera && TempModel) ? TempCamera : '---',
				model: (TempCamera && TempModel) ? (TempModel.indexOf(TempCamera) === 0 ? TempModel.substr(TempCamera.length+1) : TempModel) : '---', /* Makes sure MAKE is not displayed twice */
				exposureTime: !Number.isNaN(TempExposureTime) ? (TempExposureTime < 1 ? "1/" + Math.round(1.0 / TempExposureTime) + ' sec' : Math.round(TempExposureTime) + ' sec') : '---',
				aperture: !Number.isNaN(TempAperture) ? 'f/' + TempAperture : '---',
				focalLength: !Number.isNaN(TempFocalLength) ? TempFocalLength + ' mm' : '---',
				isoNumber: !Number.isNaN(TempISO) ? TempISO : null
			})
		});
	}

	render() {
		const {
			photography,
			invert,
			isOpen,
			isClose,
			fluid,
			totalImage
		} = this.props;	
		return (
			<div className={`modal ${invert ? 'invert' : ''} ${fluid ? 'fluid' : ''} ${isOpen ? 'open' : 'close'}`}>
				{ photography ? (
					<div className="modal-content modal-photography">
						<div className="modal-photo-details">
							<div className="details">
								<div className="detail">
									<span>
										<label className="label">Camera</label>
										<label className="val">{this.state.camera}</label>
									</span>
								</div>
								<div className="detail">
									<span>
										<label className="label">Model</label>
										<label className="val">{this.state.model}</label>
									</span>
								</div>
								<div className="detail">
									<span>
										<label className="label">Exposure time</label>
										<label className="val">{this.state.exposureTime}</label>
									</span>
								</div>
								<div className="detail">
									<span>
										<label className="label">Aperture</label>
										<label className="val">{this.state.aperture}</label>
									</span>
								</div>
								<div className="detail">
									<span>
										<label className="label">Focal length</label>
										<label className="val">{this.state.focalLength}</label>
									</span>
								</div>
								<div className="detail">
									<span>
										<label className="label">ISO</label>
										<label className="val">{this.state.isoNumber !== undefined ? this.state.isoNumber : '---'}</label>
									</span>
								</div>
								<div className="detail">
									<span>
										<label className="label">GPS</label>
										<label className="val">
											<span className="float left margin-right-24px">{this.state.latitude !== null ? this.state.latitude + ' ' + this.state.latRef : '---'}</span>
											<span className="float left">{this.state.latitude !== null ? this.state.longitude + ' ' + this.state.lngRef : ''}</span>
										</label>
									</span>
								</div>
							</div>
							<div className="map">
								{this.state.latitude !== null ? (
									<ReactMapGL
										mapboxApiAccessToken={'pk.eyJ1IjoicGlyaTg5IiwiYSI6ImNqdDR1ZDVwbDA1bzA0NHF1dTljMnl3ZDkifQ.CUywgYTR-ObRJES0XFiC9A'}
										width="100%"
										height="100%"
										latitude={this.state.latitude}
										longitude={this.state.longitude}
										zoom={8}
										onViewportChange={(viewport) => this.setState({ viewport })}
										mapStyle="mapbox://styles/piri89/cjrxl1z560zrw1fqmvyhiqn38"
										//attributionControl={false}
									>
										<Marker latitude={this.state.latitude} longitude={this.state.longitude} offsetTop={-64} offsetLeft={-15}><Icon name="location" /></Marker>
									</ReactMapGL>
								) : (
										<div className="no-map-msg">
											<Icon name="no-location" />
											<span>No location data available for this image</span>
										</div>
								)}
							</div>
						</div>						
						<div className="modal-photo">
							<img
								key={this.state.currentImage}
								ref={this.imgRef}
								src={images(this.props.images[this.state.currentImage].src)}
								alt={this.props.altLabel}
								onLoad={this.onLoadReadExif}
							/>
						</div>
						<div className="modal-close" onClick={isClose}><Icon name="close" /></div>
						<div className="modal-nav-controller">
							<div className="modal-counter">{this.state.currentImage + 1} of {totalImage}</div>
							<div className={`modal-prev ${this.state.currentImage === 0 ? 'dis' : ''}`} onClick={this.gotoPrevious}><Icon name="arrow-left" /></div>
							<div className={`modal-next ${this.state.currentImage === totalImage - 1 ? 'dis' : ''}`} onClick={this.gotoNext}><Icon name="arrow-right" /></div>
						</div>
					</div>
				) : (
					<div className="modal-content">
						{this.props.children}
					</div>
				)}
			</div>
		)
	}
}

export default Modal;
