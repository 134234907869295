import React, { Component } from 'react';
//--- Stylesheets
import './style.scss';

class SocialIcon extends Component {
	render() {
		return (
			<a className={`social-icon ${this.props.className ? this.props.className : ''}`} href={this.props.link ? this.props.link : '#0'}>
				<span className="social-abbr">{this.props.abbr}</span>
				<span className="social-name">{this.props.children}</span>
			</a>
		)
	}
}

export default SocialIcon;
