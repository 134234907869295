import React, { Component } from 'react';
//--- Stylesheets
import './style.scss';

class ProgressRectangular extends Component {
  render() {
    const diagonalSize = this.props.size * Math.sqrt(2);
		return (
      <svg
        className="progress-rect"
        height={diagonalSize + 'px'}
        width={diagonalSize + 'px'}
      >
        <rect
          className='progress-rect-stroke'
          fill="none"
          strokeWidth={2}
          strokeLinejoin="miter"
          transform={`rotate(45 ${diagonalSize/2} ${diagonalSize/2})`}
          height={this.props.size + 'px'}
          width={this.props.size + 'px'}
          x={(diagonalSize - this.props.size) / 2}
          y={(diagonalSize - this.props.size) / 2}
        />
        <rect
          className='progress-rect-progress'
          fill="none"
          strokeWidth={2}
          strokeLinejoin="miter"
          strokeDasharray={`${(((this.props.size*2) / 100) * this.props.progress) * 2} ${this.props.size * 2}`}
          transform={`rotate(45 ${diagonalSize/2} ${diagonalSize/2})`}
          height={this.props.size + 'px'}
          width={this.props.size + 'px'}
          x={(diagonalSize - this.props.size) / 2}
          y={(diagonalSize - this.props.size) / 2}
        />
        <text
          className="main-txt"
          textAnchor="middle"
          x={diagonalSize / 2}
          y={(diagonalSize / 2) + 8}
        >
          {this.props.name}
        </text>
        <text
          className="sec-txt"
          textAnchor="middle"
          x={diagonalSize / 2}
          y={(diagonalSize / 2) + 32}
        >
          {this.props.progress + '%'}
        </text>
      </svg>
    )
  }
}

export default ProgressRectangular;
