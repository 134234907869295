import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
//--- Custom Component
import BaseLayout from '../../components/BaseLayout';
import { Modal, SectionContainer } from '../../components/widgets';
import ImgComponent from './ImgComponent';
//--- Stylesheets
import './style.scss';
//--- Data
import photos from '../../data/photo.json';
//--- Images
//const images = require.context('../../../public/photography/demo', true);


class App extends Component {
  constructor() {
    super();
    this.state = {
      totalImage: photos.length,
      currentImage: 0,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  openModal(event, obj) {
    this.setState({
      currentImage: obj.index,
      modalIsOpen: true,
    });
  }
  closeModal() {
    this.setState({
      currentImage: 0,
      modalIsOpen: false
  });
  }
  render() {
    return (
      <React.Fragment>
        <BaseLayout invert>
          {/* <div><img src={images('./piriArtworkBg.JPG')} onClick={this.openModal}/></div> */}
          <SectionContainer className="photo-section">
          <Gallery
              photos={photos}
              onClick={this.openModal}
              ImageComponent={ImgComponent}
            />
          </SectionContainer>
        </BaseLayout>
        {this.state.modalIsOpen &&
          <Modal
            photography="true"
            images={photos}
            invert="true"
            isOpen={this.state.modalIsOpen}
            isClose={this.closeModal}
            totalImage={this.state.totalImage}
            currentImage={this.state.currentImage}
            altLabel={''}
          />
        }
      </React.Fragment>
    );
  }
}

export default App;
