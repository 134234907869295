import React from "react";
const images = require.context('../../../../public/photography/demo');


const ImgComponent = ({
  index,
  onClick,
  photo
}) => {
  return (
    <img
      src={images(photo.src)}
      width={photo.width}
      height={photo.height}
      style={{margin: 2}}
      //margin={2}
      //index={photo.index}
      onClick={e => onClick(e, { index, photo })}
      alt=""
    />
  );
};

export default ImgComponent;
