import React, { Component } from 'react';
//--- Custom Component
import { SectionContainer } from '../widgets';
//--- Stylesheets
import './style.scss';

class Header extends Component {
	render() {
		return (
			<div className="nav-section">
				<SectionContainer>
					<header className="top-nav">
						<div className="left-side">
							<div className="header-logo">Pranavan Selladurai</div>
						</div>
						<nav className="right-side">
							<ul>
								<li><a href="#/">Home</a></li>
								<li><a href="#/photo">Photography</a></li>
								<li><a href="#/projects">Projects</a></li>
							</ul>
						</nav>
					</header>
				</SectionContainer>
			</div>
		)
	}
}

export default Header;
