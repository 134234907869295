import React, { Component } from 'react';
//--- Stylesheets
import './style.scss';

class Headline extends Component {
	render() {
		const { as, className, children, ...Rest } = this.props;
    return(
			<React.Fragment>
				{ as === 'h0' && <span className={`h0 headline ${className ? className : ''}`} {...Rest}>{ children }</span> }
				{ as === 'h1' && <h1 className={`headline ${className ? className : ''}`} {...Rest}>{ children }</h1> }
				{ as === 'h2' && <h2 className={`headline ${className ? className : ''}`} {...Rest}>{ children }</h2> }
				{ as === 'h3' && <h3 className={`headline ${className ? className : ''}`} {...Rest}>{ children }</h3> }
				{ as === 'h4' && <h4 className={`headline ${className ? className : ''}`} {...Rest}>{ children }</h4> }
				{ as === 'h5' && <h5 className={`headline ${className ? className : ''}`} {...Rest}>{ children }</h5> }
				{ as === 'h6' && <h6 className={`headline ${className ? className : ''}`} {...Rest}>{ children }</h6> }
			</React.Fragment>
    )
  }
}

export default Headline;
